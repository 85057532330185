import React, { useContext, useRef, useState, useEffect } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TimelineComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { CreateBot as textLang } from 'staticData/languages';
import { getColor } from 'helpers/utils';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

echarts.use([
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  CanvasRenderer,
  LegendComponent,
  TimelineComponent
]);

const BacktestBarChart = ({
  data /*, getTotalForYear*/,
  showSingleYear,
  selectedMonth
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const months = textLang.months;
  const isMobile = window.innerWidth < 768;
  const [manualInteraction, setManualInteraction] = useState(
    selectedMonth == null
  );
  const chartRef = useRef(null);

  const findMinMaxValues = data => {
    for (const year in data) {
      if (Object.prototype.hasOwnProperty.call(data, year)) {
        const values = data[year];
        const numericValues = values.map(value => parseFloat(value));

        const minValue = Math.min(...numericValues);
        const maxValue = Math.max(...numericValues);

        return { minValue, maxValue };
      }
    }
    return null; // default
  };

  const { minValue, maxValue } = findMinMaxValues(data);

  const roundToTen = numero => {
    if (numero > 0) {
      return Math.ceil(numero / 10) * 10;
    } else if (numero < 0) {
      return Math.floor(numero / 10) * 10;
    } else {
      return 0; // if 0 return 0
    }
  };

  const dataFormatter = obj => {
    return Object.keys(obj).reduce((acc, val) => {
      return {
        ...acc,
        [val]: obj[val].map((value, index) => ({
          name: months[lang][index],
          value
        }))
      };
    }, {});
  };

  const dataMap = dataFormatter(data);

  const tooltipFormatter = params => {
    let tooltipItem = ``;
    if (
      params.componentType !== 'timeline' &&
      params.some(item => item.value !== 0) &&
      manualInteraction
    ) {
      if (Array.isArray(params)) {
        params?.forEach(el => {
          tooltipItem =
            tooltipItem +
            `<div class='ms-1'> 
        <h6 class="text-700">
        <div class="dot me-1 fs--2 d-inline-block" style="background-color:${
          el.borderColor ? el.borderColor : el.color
        }"></div>
        ${el.seriesName} : ${
              typeof el.value === 'object' ? el.value[1] : el.value
            }%
        </h6>
        </div>`;
        });
      }
      return `<div>
              <p class='mb-2 text-600'>
                ${
                  dayjs(params[0].axisValue).isValid()
                    ? dayjs(params[0].axisValue).format('MMMM DD')
                    : params[0].axisValue
                }
              </p>
              ${tooltipItem}
            </div>`;
    }
  };

  const getOption = () => ({
    baseOption: {
      timeline: {
        currentIndex: isMobile || showSingleYear ? 0 : 1,
        show: isMobile || showSingleYear ? false : true,
        axisType: 'category',
        data: Object.keys(data),
        label: {
          formatter: function (s) {
            return s;
          }
        },
        lineStyle: {
          color: getColor('info')
        },
        itemStyle: {
          color: getColor('secondary')
        },
        checkpointStyle: {
          color: getColor('primary'),
          shadowBlur: 0,
          shadowOffsetX: 0,
          shadowOffsetY: 0
        },
        controlStyle: {
          show: false
        }
      },
      title: {
        textStyle: {
          color: getColor('gray-700')
        }
      },
      tooltip: {
        // show: false
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: { color: getColor('gray-1100') },
        borderWidth: 1,
        transitionDuration: 0,
        formatter: tooltipFormatter
      },
      calculable: true,
      xAxis: [
        {
          type: 'category',
          data: months[lang],
          splitLine: { show: false },
          axisLabel: {
            formatter: value => {
              return value;
            },
            color: getColor('gray-600')
          },
          axisLine: {
            lineStyle: {
              color: getColor('gray-400')
            }
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: value => value + '%',
            color: getColor('gray-600')
          },
          min: minValue < -10 ? roundToTen(minValue) : -10,
          max: maxValue > 10 ? roundToTen(maxValue) : 10,
          splitLine: {
            lineStyle: {
              color: getColor('gray-800')
            }
          }
        }
      ],
      series: [
        {
          name: '',
          type: 'bar',
          itemStyle: {
            color: params => {
              const value = params.value.value || params.value;
              return value > 0
                ? getColor('success')
                : value < 0
                ? getColor('danger')
                : getColor('secondary');
            },
            borderRadius: [3, 3, 0, 0]
          }
        }
      ],
      grid: {
        top: '5%',
        bottom: isMobile || showSingleYear ? 0 : '20%',
        left: 5,
        right: 10,
        containLabel: true
      }
    },
    options: Object.keys(dataMap).map(year => ({
      series: [{ data: dataMap[year] }]
    }))
  });

  const handleMouseLeave = () => {
    if (selectedMonth != null) {
      setManualInteraction(false);
      const chart = chartRef.current.getEchartsInstance();
      chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: selectedMonth
      });
    }
  };

  const handleMouseMove = () => {
    if (selectedMonth != null) setManualInteraction(true);
  };

  useEffect(() => {
    if (selectedMonth !== null && chartRef.current) {
      const chartInstance = chartRef.current.getEchartsInstance();
      chartInstance.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: selectedMonth
      });
    }
  }, [selectedMonth]);

  return (
    <div onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove}>
      <ReactEChartsCore
        ref={chartRef}
        echarts={echarts}
        option={getOption()}
        style={{ height: '18rem' }}
      />
    </div>
  );
};

BacktestBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  getTotalForYear: PropTypes.func.isRequired,
  showSingleYear: PropTypes.string,
  selectedMonth: PropTypes.number
};

export default BacktestBarChart;
